import React, { Suspense, lazy } from "react";

import { FirebaseContextProvider } from "./context/Firebase/FirebaseContextProvider";
import { ShopifyContextProvider } from "./context/Shopify/ShopifyContextProvider";

import qs from "qs";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ROUTES, AppRoute } from "./routes";

import { ErrorBoundary } from "react-error-boundary";
import { ReactComponent as BugIcon } from "./assets/images/icons/bug.svg";

import firebase from "firebase/app";
import "firebase/analytics";

import "./App.module.scss";

const Home = lazy(() => import("./views/home"));
const TOS = lazy(() => import("./views/home/tos"));
const Privacy = lazy(() => import("./views/home/privacy"));
const Changelog = lazy(() => import("./views/home/changelog"));
const GPAdmin = lazy(() => import("./views/gp_admin"));
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const Nav = lazy(() => import("./components/Nav"));
const Checks = lazy(() => import("./Checks"));
const AuthHandler = lazy(() => import("./views/AuthHandler"));
const Layouts = lazy(() => import("./views/layouts"));

export type Params = {
  shopId: string;
  layoutId?: string;
};

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error?: Error;
  resetErrorBoundary?: () => void;
}) {
  return (
    <div className="container h-100 pb-5  d-flex align-items-center justify-content-center">
      <div className="row">
        <div className="col w-50 m-auto text-center">
          <BugIcon className="text-muted mb-5" width={60} height={60} />
          <p className="text-monospace text-info">something went wrong:</p>
          <pre className="text-wrap">{error?.message}</pre>
          <button
            className="btn btn-primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </button>
        </div>
      </div>
    </div>
  );
}

const SupsenseLoadingView = () => (
  <div className="row">
    <div className="w-100 p-5 mt-5 mb-5 d-flex flex-column align-items-center justify-content-center text-primary">
      <div className="spinner-border mb-3"></div>
    </div>
  </div>
);

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        console.log(error, info);
      }}
    >
      <Router>
        <Suspense fallback={<SupsenseLoadingView />}>
          <Switch>
            <Route path="/auth-handler" component={AuthHandler} exact />
            <Route path="/" exact component={Home} />
            <Route path="/tos" exact component={TOS} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/changelog" exact component={Changelog} />
            <Route path="/:shopId/embed" exact component={Layouts} />
            <Route
              path="/:shopId?"
              render={(props) => {
                if (firebase.apps.length !== 0) {
                  firebase.analytics().logEvent("page_view", {
                    page_location: window.location.href,
                    page_path: props.location.pathname,
                    page_title: window.location.href,
                  });
                }

                if (!props.match.params.shopId) {
                  return null;
                }
                const isAdminRoute = !!props.location.pathname.includes(
                  "/admin/"
                );
                const { ui } = qs.parse(props.location.search, {
                  ignoreQueryPrefix: true,
                });

                const isIframeRoute = ui === "minimal";

                return (
                  <FirebaseContextProvider router={props}>
                    <ShopifyContextProvider router={props}>
                      <Checks
                        isAdminRoute={isAdminRoute}
                        shopId={props.match.params.shopId}
                      >
                        <>
                          {!isIframeRoute && <Nav />}
                          <Switch>
                            {ROUTES.map((route: AppRoute, index: number) => {
                              return (
                                <ProtectedRoute
                                  isAdminRoute={isAdminRoute}
                                  shopId={props.match.params.shopId}
                                  requiresAuth={route.requiresAuth}
                                  exact={route.exact}
                                  key={index}
                                  path={route.path}
                                  component={route.component}
                                />
                              );
                            })}
                            <ProtectedRoute
                              isAdminRoute={true}
                              shopId={props.match.params.shopId}
                              requiresAuth={true}
                              exact={true}
                              key="gp-admin-route"
                              path="/:shopId/gp-admin"
                              component={GPAdmin}
                            />
                          </Switch>
                        </>
                      </Checks>
                    </ShopifyContextProvider>
                  </FirebaseContextProvider>
                );
              }}
            />
          </Switch>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
