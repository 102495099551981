import { createContext, Context } from "react";
import { ShopDetails } from "./ShopifyContextProvider";

interface ShopifyContextProps {
  shopDetails: ShopDetails | null;
  checkSignUpEmail: (email: string) => Promise<boolean>;
  checkShopExists: (shopId: string) => Promise<boolean>;
}

export const ShopifyContext: Context<ShopifyContextProps | null> = createContext<ShopifyContextProps | null>(
  null
);
