import { Suspense, lazy, FunctionComponent } from "react";

// import { SignIn as UserSignIn, SignUp as UserSignUp } from "./views/user/auth";
// import {
//   SignIn as AdminSignIn,
//   SignUp as AdminSignUp,
// } from "./views/admin/auth";

const Home = lazy(() => import("./views/home"));
const Feed = lazy(() => import("./views/user/feed"));

const UserSignIn = lazy(() => import("./views/user/auth/SignIn"));
const UserSignUp = lazy(() => import("./views/user/auth/SignUp"));
const UserSettings = lazy(() => import("./views/user/settings"));

const AdminSignIn = lazy(() => import("./views/admin/auth/SignIn"));
const AdminSignUp = lazy(() => import("./views/admin/auth/SignUp"));
const AdminSettings = lazy(() => import("./views/admin/settings"));

export interface AppRoute {
  label: string;
  path: string;
  component: FunctionComponent;
  url: (shopId: string) => string;
  showInNav: (isAdmin: boolean) => boolean;
  exact?: boolean;
  requiresAuth: boolean;
}

export interface SidenavRoute {
  label: string;
  link: (shopId: string) => string;
  active?: { path: string; exact: boolean };
}

export interface SideNavSection {
  label: string;
  icon?: string;
  visible: (visible: boolean) => boolean;
  routes: SidenavRoute[];
}

export const ADMIN_SETTINGS_ROUTES: SideNavSection[] = [
  {
    label: "General Settings",
    icon: "sliders.svg",
    visible: () => true,
    routes: [
      {
        label: "Profile",
        link: (shopId) => `/${shopId}/admin/settings/profile`,
        active: { path: "/:shopId/admin/settings/profile", exact: true },
      },
      {
        label: "Affiliates",
        link: (shopId) => `/${shopId}/admin/settings/affiliates`,
        active: { path: "/:shopId/admin/settings/affiliates", exact: true },
      },
      {
        label: "Rewards",
        link: (shopId) => `/${shopId}/admin/settings/rewards`,
        active: { path: "/:shopId/admin/settings/rewards", exact: true },
      },
      {
        label: "Billing",
        link: (shopId) => `/${shopId}/admin/settings/billing`,
        active: { path: "/:shopId/admin/settings/billing", exact: true },
      },
    ],
  },
  {
    label: "Affiliate Data",
    icon: "people.svg",
    visible: () => true,
    routes: [
      {
        label: "Orders",
        link: (shopId) => `/${shopId}/admin/settings/affiliates/stats`,
        active: {
          path: "/:shopId/admin/settings/affiliates/stats",
          exact: true,
        },
      },
      {
        label: "Members",
        link: (shopId) => `/${shopId}/admin/settings/affiliates/members`,
        active: {
          path: "/:shopId/admin/settings/affiliates/members",
          exact: true,
        },
      },
      {
        label: "Payments",
        link: (shopId) => `/${shopId}/admin/settings/affiliates/payments`,
        active: {
          path: "/:shopId/admin/settings/affiliates/payments",
          exact: true,
        },
      },
      {
        label: "Pending Affiliates",
        link: (shopId) => `/${shopId}/admin/settings/queues/affiliates`,
        active: {
          path: "/:shopId/admin/settings/queues/affiliates",
          exact: true,
        },
      },
    ],
  },
  {
    label: "Feed Settings",
    icon: "card-list.svg",
    visible: () => true,
    routes: [
      {
        label: "Feed Embed",
        link: (shopId) => `/${shopId}/admin/settings/feed/layouts`,
        active: { path: "/:shopId/admin/settings/feed/layouts", exact: true },
      },
      {
        label: "Pending Posts",
        link: (shopId) => `/${shopId}/admin/settings/queues/posts`,
        active: { path: "/:shopId/admin/settings/queues/posts", exact: true },
      },
    ],
  },
  {
    label: "Other",
    visible: () => true,
    routes: [
      {
        label: "Getting Started",
        link: (shopId) => `/${shopId}/admin/settings`,
        active: { path: "/:shopId/admin/settings", exact: true },
      },
      {
        label: "View as User",
        link: (shopId) => `/${shopId}/user/settings`,
        active: { path: "", exact: true },
      },
    ],
  },
];

export const USER_SETTINGS_ROUTES: SideNavSection[] = [
  {
    label: "General Settings",
    icon: "sliders.svg",
    visible: () => true,
    routes: [
      {
        label: "Profile",
        link: (shopId) => `/${shopId}/user/settings/profile`,
        active: { path: "/:shopId/user/settings/profile", exact: true },
      },
    ],
  },
  {
    label: "Affiliate Program",
    icon: "cash.svg",
    visible: (isAffiliate: boolean) => isAffiliate,
    routes: [
      {
        label: "Program Overview",
        link: (shopId) => `/${shopId}/user/settings/affiliate`,
        active: { path: "/:shopId/user/settings/affiliate", exact: true },
      },
      {
        label: "Order History",
        link: (shopId) => `/${shopId}/user/settings/affiliate/history`,
        active: {
          path: "/:shopId/user/settings/affiliate/history",
          exact: true,
        },
      },
      {
        label: "Payouts",
        link: (shopId) => `/${shopId}/user/settings/affiliate/payouts`,
        active: {
          path: "/:shopId/user/settings/affiliate/payouts",
          exact: true,
        },
      },
    ],
  },
  {
    label: "Other",
    visible: () => true,
    routes: [
      {
        label: "Getting Started",
        link: (shopId) => `/${shopId}/user/settings`,
        active: { path: "/:shopId/user/settings", exact: true },
      },
      {
        label: "Rewards Received",
        link: (shopId) => `/${shopId}/user/settings/rewards`,
        active: { path: "/:shopId/user/settings/rewards", exact: true },
      },
      {
        label: "Post History",
        link: (shopId) => `/${shopId}/user/settings/posts`,
        active: { path: "/:shopId/user/settings/posts", exact: true },
      },
    ],
  },
];

export const ROUTES: AppRoute[] = [
  // {
  //   label: "Embedded Feed",
  //   path: "/:shopId/embed",
  //   component: Layouts,
  //   url: () => "/embed",
  //   showInNav: () => false,
  //   exact: true,
  //   requiresAuth: false,
  // },
  {
    label: "Home",
    path: "/",
    component: Home,
    url: () => "/",
    showInNav: () => false,
    exact: true,
    requiresAuth: false,
  },
  {
    label: "Feed",
    path: "/:shopId",
    component: Feed,
    url: (shopId) => `/${shopId}`,
    showInNav: () => true,
    exact: true,
    requiresAuth: false,
  },
  {
    label: "User Sign In",
    path: "/:shopId/user/sign-in",
    component: UserSignIn,
    url: (shopId) => `/${shopId}/user/sign-in`,
    showInNav: () => false,
    exact: true,
    requiresAuth: false,
  },
  {
    label: "User Sign Up",
    path: "/:shopId/user/sign-up",
    component: UserSignUp,
    url: (shopId) => `/${shopId}/user/sign-up`,
    showInNav: () => false,
    exact: true,
    requiresAuth: false,
  },
  {
    label: "Admin Sign Up",
    path: "/:shopId/admin/sign-up",
    component: AdminSignUp,
    url: (shopId) => `/${shopId}/admin/sign-up`,
    showInNav: () => false,
    exact: true,
    requiresAuth: false,
  },
  {
    label: "Admin Sign In",
    path: "/:shopId/admin/sign-in",
    component: AdminSignIn,
    url: (shopId) => `/${shopId}/admin/sign-in`,
    showInNav: () => false,
    exact: true,
    requiresAuth: false,
  },
  {
    label: "Settings",
    path: "/:shopId/admin/settings",
    component: AdminSettings,
    url: (shopId) => `/${shopId}/admin/settings`,
    showInNav: (isAdmin) => isAdmin,
    exact: false,
    requiresAuth: true,
  },
  {
    label: "Settings",
    path: "/:shopId/user/settings",
    component: UserSettings,
    url: (shopId) => `/${shopId}/user/settings/profile`,
    showInNav: (isAdmin) => !isAdmin,
    exact: false,
    requiresAuth: true,
  },
];
