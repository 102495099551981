import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  loading: boolean;
  showLoader?: boolean;
  label?: string;
}

export const FloatingSpinner: FC<Props> = ({
  loading,
  showLoader = true,
  label = "saving...",
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let timeout: any;

    if (loading) {
      setIsLoading(true);
    } else {
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 1250);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [loading]);

  const wrapper = classnames(styles.wrapper, "card shadow-lg", {
    [styles.isLoading]: isLoading,
  });

  return (
    <div className={wrapper}>
      <div className="card-body">
        <div className="row">
          <div className="col text-monospace">{label}</div>
          {showLoader && (
            <div className="col">
              <div className="ml-3 spinner-border text-primary spinner-border-sm" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
