import React, { useEffect, useState } from "react";

import firebase from "firebase/app";
import "firebase/firestore";

import { ShopifyContext } from "./ShopifyContext";
import { useFirebase } from "../Firebase/useFirebase";
import { RouteComponentProps, useParams } from "react-router-dom";
import { Params } from "../../App";
import { FloatingSpinner } from "../../components/FloatingSpinner";
export interface ShopDetails {
  id?: number;
  name?: string;
  email?: string;
  domain?: string;
  province?: string;
  country?: string;
  address1?: string;
  zip?: string;
  city?: string;
  source?: null;
  phone?: string;
  latitude?: number;
  longitude?: number;
  primary_locale?: string;
  address2?: string;
  created_at?: string;
  updated_at?: string;
  country_code?: string;
  country_name?: string;
  currency?: string;
  customer_email?: string;
  timezone?: string;
  iana_timezone?: string;
  shop_owner?: string;
  money_format?: string;
  money_with_currency_format?: string;
  weight_unit?: string;
  province_code?: string;
  taxes_included?: null;
  tax_shipping?: null;
  county_taxes?: true;
  plan_display_name?: string;
  plan_name?: string;
  has_discounts?: true;
  has_gift_cards?: true;
  myshopify_domain?: string;
  google_apps_domain?: null;
  google_apps_login_enabled?: null;
  money_in_emails_format?: string;
  money_with_currency_in_emails_format?: string;
  eligible_for_payments?: true;
  requires_extra_payments_agreement?: false;
  password_enabled?: false;
  has_storefront?: true;
  eligible_for_card_reader_giveaway?: false;
  finances?: true;
  primary_location_id?: number;
  cookie_consent_level?: string;
  visitor_tracking_consent_preference?: string;
  force_ssl?: true;
  checkout_api_supported?: true;
  multi_location_enabled?: false;
  setup_required?: false;
  pre_launch_enabled?: false;
  enabled_presentment_currencies?: string[];
}

export function ShopifyContextProvider({
  children,
  router,
}: {
  children: React.ReactElement | React.ReactElement[];
  router: RouteComponentProps;
}) {
  const [shopDetails, setShopDetails] = useState<ShopDetails | null>(null);
  const [error, setError] = useState<string>();

  const { user, shop } = useFirebase();
  const { shopId } = useParams<Params>();
  const get = async ({ path, shopId }: { path: string; shopId: string }) => {
    const fn = firebase.functions().httpsCallable("shopify-get");
    try {
      const {
        data: { shop },
      } = await fn({ path, shopId });
      setShopDetails(shop);
    } catch (error) {
      // handleError(error)
      setError("Something went wrong. You may need to reload the page");
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      !shopDetails &&
      user &&
      shop?.admin &&
      (shop?.admin || []).includes(user.uid) &&
      user?.uid &&
      shopId
    ) {
      get({
        path: "shop.json",
        shopId: shopId,
      });
    }
  }, [user, shopId, shopDetails, shop]);

  // Used when signing up an admin to make sure email matches that of store owner
  const checkSignUpEmail = async (email: string) => {
    const fn = firebase.functions().httpsCallable("shopify-checkSignUpEmail");
    const { data } = await fn({ email, shopId: shopId });

    return data as boolean;
  };

  // Use this when you don't have a token to make sure the store exists
  const checkShopExists = async (shopId: string) => {
    const fn = firebase
      .functions()
      .httpsCallable("shopify-checkStoreInstalled");
    const { data } = await fn({ shopId });

    return data;
  };

  return (
    <ShopifyContext.Provider
      value={{
        shopDetails,
        checkSignUpEmail,
        checkShopExists,
      }}
    >
      <FloatingSpinner loading={!!error} showLoader={false} label={error} />
      {children}
    </ShopifyContext.Provider>
  );
}
