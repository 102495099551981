import { createContext, Context } from "react";
import {
  Notification,
  UserDetails,
  FirebaseShopDetails,
  RewardDetails,
} from "./FirebaseContextProvider";
import { Post } from "../../components/PostEntry";
interface ValueType {
  userDetails: UserDetails | null;
  signOut: () => void;
  shopDetails: FirebaseShopDetails | null;
  postDetails: Post[] | null;
  rewardDetails: RewardDetails[] | null;
  notificationDetails: { [key: string]: Notification } | null;
}
export const FirebaseContext: Context<ValueType | null> = createContext<ValueType | null>(
  null
);
