import { useContext } from "react";

import { FirebaseContext } from "./FirebaseContext";

export const useFirebase = () => {
  return {
    user: useContext(FirebaseContext)?.userDetails,
    shop: useContext(FirebaseContext)?.shopDetails,
    posts: useContext(FirebaseContext)?.postDetails,
    // affiliateMembers: useContext(FirebaseContext)?.affiliateMemberDetails,
    rewards: useContext(FirebaseContext)?.rewardDetails,
    signOut: useContext(FirebaseContext)?.signOut,
    notifications: useContext(FirebaseContext)?.notificationDetails,
  };
};
