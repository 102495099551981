export const FB_CONFIG = {
  apiKey: "AIzaSyAJdZkN5Qko-itU_cl-4yhw9VFD17_vaBM",
  authDomain: "shopify-feeder.firebaseapp.com",
  databaseURL: "https://shopify-feeder.firebaseio.com",
  projectId: "shopify-feeder",
  storageBucket: "shopify-feeder.appspot.com",
  messagingSenderId: "232446554517",
  appId: "1:232446554517:web:c6be70b2da4573676d069c",
  measurementId: "G-JEJGWC15W2",
};
