import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/auth";
import { FirebaseContext } from "./FirebaseContext";
import { RouteComponentProps } from "react-router-dom";
import { Post } from "../../components/PostEntry";
import { Order } from "shopify-admin-api-typings";
// import { useErrorHandler } from 'react-error-boundary';
import { FloatingSpinner } from "../../components/FloatingSpinner";

import { FB_CONFIG } from "../../FB_CONFIG";
export interface Notification {
  id: string;
  timestamp: string;
  read: boolean;
  read_timestamp?: string;
  cleared: boolean;
  title: string;
  body: string;
  link: string;
  type: "DEFAULT" | "DANGER";
}

export type FirebaseDate = {
  toDate: () => Date;
};

export type AffiliateOrder2 = {
  created_at: FirebaseDate;
  order_status:
    | "pending"
    | "authorized"
    | "partially_paid"
    | "paid"
    | "partially_refunded"
    | "refunded"
    | "voided";
  uid: string;
  totalPrice: number;
  totalPriceAfterDiscount: number;
  totalPriceWithoutShipping: number;
  totalDiscountApplied: number;
  affiliatePriceRuleUsed: RewardDetails["price_rule"];
  affiliatePayout: {
    status: "PAID" | "OPEN" | "INVALIDATED";
    validOn: FirebaseDate;
    paidOn?: FirebaseDate;
    paymentMethod?: FirebaseShopDetails["payouts:method"];
    paymentConfirmation?: any;
    amount: number;
  };
  original: Order;
  affiliatePayoutRuleUsed?: {
    paymentMethod: FirebaseShopDetails["payouts:method"];
    termsDays: FirebaseShopDetails["payouts:termsDays"];
    type: FirebaseShopDetails["payouts:type"];
    value: FirebaseShopDetails["payouts:value"];
  };
};
export type AffiliateHistory2 = {
  totalOrders: number;
  totalSales: number;
  totalDiscountsGiven: number;
  totalPayoutsReceived: number;
  updated_at: FirebaseDate;
  orders: string[];
};

export type UserDetails = {
  firstName?: string;
  lastName?: string;
  photoURL: string;
  displayName: string;
  email: string;
  emailVerified?: boolean;
  admin: string[];
  posts?: string[];
  isAdmin: boolean;
  uid: string;
  requestedAffiliateAccess: boolean;
  affiliate: boolean;
  affiliateStatus?: "ACTIVE" | "REVOKED" | "PENDING" | "NONE";
  affiliateCode?: RewardDetails["discount_code"];
  affiliateLink?: string;
  affiliateLinkHash?: string | boolean;
  affiliateLinkIsShortened?: boolean;
  bio?: string;
  twitterHandle?: string;
  youtubeHandle?: string;
  instagramHandle?: string;
  notifications?: { [key: string]: Notification };
  rewardsReceived?: { [key: string]: RewardDetails };
  joined: string;
  joinedAffiliate?: string;
  shopifyUserId?: number;
  affiliateHistory?: AffiliateHistory2;
  notificationSettings: {
    postStatus: boolean;
    affiliateStatus: boolean;
    payout: boolean;
    rewards: boolean;
    postsForReview: boolean;
    affiliatesForReview: boolean;
    payoutsDue: boolean;
    nightlyUpdate: boolean;
  };
};

interface RecurringApplicationCharge {
  id: number;
  name: string;
  api_client_id: number;
  price: string;
  status: string;
  return_url: string;
  billing_on: string;
  created_at: string;
  updated_at: string;
  test?: boolean;
  activated_on: string;
  cancelled_on: string;
  trial_days: number;
  trial_ends_on: string;
  decorated_return_url: string;
}

export interface FirebaseShopDetails {
  // savedLayouts: SavedLayout[];
  shopifyId: number;
  status?: "UNINSTALLED" | "INSTALLED";
  billing: {
    charges?: RecurringApplicationCharge[];
    activePlan?: RecurringApplicationCharge;
    applicationCredits: {
      [id: string]: { amount: string; description: string };
    };
    plans: {
      standard: {
        recurring_application_charge: {
          name: string;
          price: number;
          return_url: string;
          test?: boolean;
          trial_days: number;
        };
      };
    };
  };
  users: string[];
  id: string;
  scope: string;
  admin: string[];
  posts: Post[];
  referrals: boolean;
  payouts: boolean;
  "payouts:type": "FIXED" | "PERCENTAGE";
  "payouts:value": number;
  "payouts:termsDays": number;
  "payouts:method": "MANUAL" | "BANK";
  "affiliates:approveFirst": boolean;
  rewards: boolean;
  affiliatePriceRule?: RewardDetails["price_rule"];
  affiliatesByCode: { [discountCode: string]: string };
  affiliatesCodeHasBeenUsed?: boolean;
  affiliateHelpText?: string;
  domain: string;
  affiliateRedirectSettings?: {
    autoApplyRedirect: boolean;
    redirectAfterAutoApply: boolean;
    redirectAfterAutoApplyDestination: string;
    from: string;
    to: string;
    useUtm?: boolean;
    shorten?: boolean;
    utm?: {
      utm_source: string;
      utm_medium: string;
      utm_campaign: string;
      utm_term: string;
      utm_content: string;
    };
  };
}
export interface RewardDetails {
  id: string;
  timestamp: string;
  active: boolean;
  awardedTo: string[];
  productTitle?: string;
  discount_code?: {
    code: string;
    created_at: string;
    id: number;
    price_rule_id: number;
    updated_at: string;
    usage_count: 0;
  };
  price_rule: {
    id?: number;
    title: string;
    target_type: "line_item";
    once_per_customer: boolean;
    target_selection: "entitled" | "all";
    entitled_product_ids?: number[];
    allocation_method: "each" | "across";
    value_type: "percentage" | "fixed_amount";
    value: number;
    customer_selection: "prerequisite" | "all";
    prerequisite_customer_ids?: number[] | undefined;
    starts_at: string;
  };
}

export function FirebaseContextProvider({
  children,
  router,
}: {
  children: React.ReactElement | React.ReactElement[];
  router: RouteComponentProps;
}) {
  const [user, setUser] = useState<firebase.User>();

  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [shopDetails, setShopDetails] = useState<FirebaseShopDetails | null>(
    null
  );
  const [postDetails, setPostDetails] = useState<Post[] | null>([]);
  const [notificationDetails, setNotificationDetails] = useState<{
    [key: string]: Notification;
  } | null>(null);

  const [rewardDetails, setRewardDetails] = useState<RewardDetails[] | null>(
    []
  );

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const {
    history,
    match: { params },
  } = router as any;

  const [error, setError] = useState<string>();

  const handleSignOut = async () => {
    setUserDetails(null);
    setShopDetails(null);
    setPostDetails(null);
    setIsAdmin(false);
    firebase.auth().signOut();
    history.replace("/");
  };

  const shopIdFromParams = params?.shopId;

  useEffect(() => {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(FB_CONFIG);
      firebase.analytics();
      firebase.firestore().enablePersistence();
      // firebase.functions().useEmulator("http://localhost", 5001);
    }
    const authListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(undefined);
      }
    });

    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let userListener: any;
    let shopListener: any;
    let postListener: any;
    let queueListener: any;
    let affiliateQueueListener: any;
    let affiliateMemberListener: any;
    let rewardListener: any;
    let notificationListener: any;

    if (shopIdFromParams) {
      postListener = firebase
        .firestore()
        .collection("posts")
        .doc(shopIdFromParams)
        .collection("posts")
        .where("reviewed", "==", true)
        .orderBy("timestamp", "desc")
        .onSnapshot(
          (snap) => {
            const data = snap.docs.map((doc) => {
              return doc.data();
            });
            setPostDetails(data as Post[]);
          },
          (error) => {
            // handleError(error)
            setError("Something went wrong. You may need to reload the page");
            console.log(error);
          }
        );
    }
    if (!!user) {
      if (user && user.uid && shopIdFromParams) {
        notificationListener = firebase
          .firestore()
          .collection("notifications")
          .doc(shopIdFromParams)
          .collection(user.uid)
          .onSnapshot((snap) => {
            const output: { [key: string]: Notification } = {};
            for (const notification of snap.docs) {
              const data = notification.data() as Notification;
              output[notification.id] = data;
            }
            // handleError(new Error('lol'))
            setNotificationDetails(output);
          });

        userListener = firebase
          .firestore()
          .collection("users")
          .doc(shopIdFromParams)
          .collection("users")
          .doc(user.uid)
          .onSnapshot(
            (snap) => {
              const data = snap.data() as UserDetails;
              setUserDetails({
                ...data,
                isAdmin: isAdmin,
              });
            },
            (error) => {
              // handleError(error)
              setError("Something went wrong. You may need to reload the page");
              console.log(error);
            }
          );
      }

      if (user && user.uid && shopIdFromParams && isAdmin) {
        rewardListener = firebase
          .firestore()
          .collection("shops")
          .doc(shopIdFromParams)
          .collection("rewards")
          .onSnapshot(
            (snap) => {
              const data = snap.docs.map((doc) => {
                return doc.data();
              });
              setRewardDetails(data as RewardDetails[]);
            },
            (error) => {
              // handleError(error)
              setError("Something went wrong. You may need to reload the page");
              console.log(error);
            }
          );
      }
    } else {
      if (affiliateMemberListener) {
        affiliateMemberListener();
      }
      if (notificationListener) {
        notificationListener();
      }
      if (affiliateQueueListener) {
        affiliateQueueListener();
      }
      if (shopListener) {
        shopListener();
      }
      if (queueListener) {
        queueListener();
      }
      if (userListener) {
        userListener();
      }
      if (rewardListener) {
        rewardListener();
      }
    }

    return () => {
      if (affiliateMemberListener) {
        affiliateMemberListener();
      }
      if (notificationListener) {
        notificationListener();
      }
      if (affiliateQueueListener) {
        affiliateQueueListener();
      }
      if (shopListener) {
        shopListener();
      }
      if (queueListener) {
        queueListener();
      }
      if (userListener) {
        userListener();
      }
      if (postListener) {
        postListener();
      }
      if (rewardListener) {
        rewardListener();
      }
    };
  }, [user, isAdmin, shopIdFromParams]);

  useEffect(() => {
    let subscribed = true;
    let shopListener: any;
    // TODO: this only needs to happen once?
    if (
      user &&
      user.uid &&
      userDetails &&
      userDetails.uid &&
      shopIdFromParams
    ) {
      shopListener = firebase
        .firestore()
        .collection("shops")
        .doc(shopIdFromParams)
        .onSnapshot(
          (snap) => {
            const data = snap.data() as FirebaseShopDetails;
            if (subscribed) {
              setShopDetails({
                ...data,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    return () => {
      subscribed = false;
      if (shopListener) {
        shopListener();
      }
    };
  }, [user, userDetails, shopIdFromParams]);

  useEffect(() => {
    if (shopDetails?.admin && userDetails?.uid) {
      if ((shopDetails?.admin || []).includes(userDetails?.uid)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [shopDetails, shopIdFromParams, userDetails]);

  if (firebase.apps.length === 0) {
    return null;
  }

  return (
    <FirebaseContext.Provider
      value={{
        userDetails: userDetails,
        notificationDetails: notificationDetails,
        signOut: handleSignOut,
        shopDetails: shopDetails,
        postDetails: postDetails,
        rewardDetails: rewardDetails,
      }}
    >
      <FloatingSpinner loading={!!error} showLoader={false} label={error} />
      {children}
    </FirebaseContext.Provider>
  );
}
